<template>
    <main class="new-details-index-page">
        <div class="new-details-index-page-wrapper">
            <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" />

            <div class="post">
                <h1 class="post__title">{{ noveltyData.novelty.title }}</h1>
                <picture v-if="noveltyData.novelty.image" class="post__image">
                    <img
                        :src="noveltyData.novelty.image.url"
                        :alt="noveltyData.novelty.title"
                        :title="noveltyData.novelty.title"
                    />
                </picture>
                <div class="post__description" v-html="removeXSS(noveltyData.novelty.content)" />
                <BWidgetUser :post="noveltyData.novelty" class="block md:flex" />
            </div>
            <div v-if="noveltyData.similar_novelties.length" class="similar-news">
                <h2 class="title">{{ $lang.pages.novedadesSlug.last_novelties }}</h2>
                <CardNews
                    v-for="(item, index) in noveltyData.similar_novelties"
                    :key="index"
                    class="card"
                    :data="item"
                />
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
import { removeXSS, replacePlaceholders, textShortener } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()

const Route = useRoute()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('novelties-page-data', () =>
    $fetch<Api.Responses.Pages.Novelty>(endpoints.pages.novelty.path + '/' + Route.params.slug, {
        headers: buildHeaders(),
        baseURL,
        method: 'GET',
    }).catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const noveltyData = ref(responseData.value.data)

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const currentDates = currentDate()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
        [':title', textShortener(noveltyData.value.novelty.title || '', 40)],
        [
            ':description',
            textShortener(
                noveltyData.value.novelty.content?.replace(/<\/?[a-z][a-z0-9]*[^<>]*>|<!--.*?-->/gim, '') ||
                    '',
                150,
            ),
        ],
        [':site', siteName || ''],
    ],
    [noveltyData.value.novelty.seo?.title || '', noveltyData.value.novelty.seo?.description || ''],
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.novedadesSlug.home,
        },
        {
            path: `/${$lang.routes.novelties}`,
            title: $lang.pages.novedadesSlug.novelties,
        },
        {
            title: noveltyData.value.novelty.title || '',
        },
    ]
})
</script>

<style lang="postcss" scoped>
.new-details-index-page {
    @apply container mb-5 mt-1;
    &-wrapper {
        @apply mx-auto max-w-4xl space-y-3 rounded-xl;
        .post {
            @apply space-y-5;
            &__title {
                @apply text-[28px] font-medium lg:text-[32px];
            }
            &__description {
                @apply prose;
            }
            &__image {
                @apply block h-40 w-full md:h-60;
                img {
                    @apply h-full w-full object-cover;
                }
            }
        }
        .similar-news {
            @apply space-y-4;
            .title {
                @apply text-lg lg:text-[28px];
            }
            .card {
                @apply rounded-xl bg-white p-2;
            }
        }
    }
}
</style>
